import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 9,
    title: "Formik x React",
    desc: "Tecniche e funzionalità di una delle librerie più utilizzate.",
    img: "/blog-image/formik.jpg",
    page: "blog/formik",
    data: "30 Ago 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Nel vasto panorama dello sviluppo web con React, la gestione dei form è una delle sfide più comuni.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Nel vasto panorama dello sviluppo web
                                        con React, la gestione dei form è una
                                        delle sfide più comuni. L'input da parte
                                        degli utenti è cruciale in molte
                                        applicazioni, ma può essere complesso da
                                        gestire. È qui che entra in gioco
                                        Formik, una libreria JavaScript che
                                        semplifica notevolmente la gestione dei
                                        form in applicazioni React.
                                        Approfondiamo ora Formik, le sue
                                        principali funzionalità e le tecniche di
                                        programmazione associate.
                                    </p>

                                    <p>
                                        Formik è una libreria JavaScript che
                                        fornisce uno strumento potente per
                                        gestire i form in applicazioni React. È
                                        progettata per semplificare la cattura
                                        dei dati dei form, la loro validazione e
                                        la gestione degli stati, riducendo
                                        notevolmente la complessità della
                                        gestione dei form in React.
                                    </p>
                                    <h3>Principali Funzionalità di Formik</h3>
                                    <h5>1. Gestione dello stato dei Form</h5>
                                    <p>
                                        Formik offre un sistema di gestione
                                        dello stato per i form. Risulta quindi
                                        facile accedere e modificare i dati del
                                        form senza dover gestire manualmente lo
                                        stato dei componenti React.
                                    </p>
                                    <h5>2. Validazione dei Form</h5>
                                    <p>
                                        La validazione dei dati dei form è una
                                        parte critica della loro gestione.
                                        Formik semplifica questo processo
                                        definendo schemi di validazione tramite
                                        un oggetto JavaScript. È possibile
                                        specificare regole di validazione per
                                        ciascun campo del form e Formik
                                        controllerà automaticamente la validità
                                        dei dati.
                                    </p>
                                    <h5>3. Gestione degli Eventi</h5>
                                    <p>
                                        Formik offre una gestione degli eventi
                                        integrata, consentendo di gestire eventi
                                        come la sottomissione del form, la
                                        modifica dei campi o altri eventi
                                        personalizzati. Questo rende più
                                        semplice collegare azioni specifiche
                                        agli eventi dei form.
                                    </p>
                                    <h5>4. Manipolazione dei Campi</h5>
                                    <p>
                                        Formik semplifica la manipolazione dei
                                        campi del form, consentendo l'accesso ai
                                        valori dei campi e la loro modifica in
                                        modo agevole.
                                    </p>
                                    <h5>
                                        5. Tocco dei campi da parte dell’utente
                                    </h5>
                                    <p>
                                        In molte applicazioni, è importante
                                        tenere traccia di quali campi sono stati
                                        toccati dagli utenti. Formik gestisce
                                        questa funzionalità, consentendo di
                                        identificare facilmente i campi
                                        interessati.
                                    </p>
                                    <h5>6. Gestione degli Errori</h5>
                                    <p>
                                        La gestione degli errori è fondamentale
                                        nei form. Formik semplifica la
                                        visualizzazione degli errori associati a
                                        ciascun campo e la loro gestione.
                                    </p>
                                    <h5>Integrazione con Componenti UI</h5>
                                    <p>
                                        Formik può essere facilmente integrato
                                        con componenti UI di terze parti, come
                                        Material-UI o Bootstrap. Questo rende la
                                        creazione di form esteticamente
                                        gradevoli e coerenti un processo più
                                        semplice.
                                    </p>
                                    <h3>
                                        Tecniche di Programmazione con Formik
                                    </h3>
                                    <p>
                                        Possiamo ora individuare alcune delle
                                        tecniche di programmazione comuni
                                        associate all'uso di questa libreria.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>1. Creazione di un Form con Formik.</h5>
                                    <p>
                                        Per utilizzare Formik, è necessario
                                        avvolgere il componente del form con il
                                        componente `Formik`. Questo componente
                                        accetta molte prop, ma le più importanti
                                        sono `initialValues`, `onSubmit`, e
                                        `validationSchema`. Ad esempio:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { Formik, Form, Field } from 'formik';
    import * as Yup from 'yup';

    const initialValues = {
        name: '',
        email: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Il nome è obbligatorio'),
        email: Yup.string().email('Email non valida').required('L\'email è obbligatoria'),
    });

    const onSubmit = (values) => {
        // Esegui azioni con i dati del form
    };

    function MyForm() {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <Form>
                    <div>
                        <label htmlFor="name">Nome:</label>
                        <Field type="text" id="name" name="name" />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <Field type="email" id="email" name="email" />
                    </div>
                    <button type="submit">Invia</button>
                </Form>
            </Formik>
        );
    }
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        In questo esempio, stiamo creando un
                                        form con due campi, `name` ed `email`, e
                                        definiamo uno schema di validazione
                                        usando Yup.
                                    </p>
                                    <h5>2. Gestione degli Eventi</h5>
                                    <p>
                                        Formik offre un sistema di gestione
                                        degli eventi integrato. Ad esempio,
                                        possiamo gestire l'evento di
                                        sottomissione del form in questo modo:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
            // Esegui azioni con i dati del form

            // Una volta completato, puoi reimpostare lo stato di "submitting" a falso
            setSubmitting(false);
        }}
        >
    </Formik>
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>3. Visualizzazione degli Errori</h5>
                                    <p>
                                        Per visualizzare gli errori associati ai
                                        campi del form, possiamo utilizzare il
                                        componente `ErrorMessage` fornito da
                                        Formik:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { ErrorMessage } from 'formik';

    <Field type="text" id="name" name="name" />
    <ErrorMessage name="name" />
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        Questo renderà automaticamente il
                                        messaggio di errore associato al campo
                                        `name` se è presente un errore di
                                        validazione.
                                    </p>
                                    <h5>4. Gestione dei Campi Toccati</h5>
                                    <p>
                                        Formik tiene traccia di quali campi sono
                                        stati toccati dagli utenti. Questo è
                                        utile per mostrare messaggi di errore
                                        solo dopo che l'utente ha toccato un
                                        campo. Per accedere a questa
                                        informazione, possiamo utilizzare la
                                        prop `touched`:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    <Field type="text" id="name" name="name" />
    {formik.touched.name && formik.errors.name ? (
    <div>{formik.errors.name}</div>
    ) : null}
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>5. Integrazione con Librerie UI</h5>
                                    <p>
                                        Formik può essere facilmente integrato
                                        con componenti UI di terze parti. Ad
                                        esempio, con Material-UI, si possono
                                        utilizzare i componenti `TextField` come
                                        campi del form all'interno di Formik:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    import { TextField } from '@material-ui/core';

    <Field
        as={TextField}
        type="text"
        id="name"
        name="name"
        label="Nome"
        variant="outlined"
    />
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="p-3">
                                        Questo integra in modo trasparente i
                                        componenti di Material-UI all'interno di
                                        un form gestito da Formik.
                                    </p>
                                    <h3>Buon sviluppo a tutti.</h3>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
